import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";



const firebaseConfig = {
  apiKey: "AIzaSyCiUFHvKO1F7Flav1vNbQP0tA_uOrOFSKg",
  authDomain: "nexos-997a7.firebaseapp.com",
  projectId: "nexos-997a7",
  storageBucket: "nexos-997a7.appspot.com",
  messagingSenderId: "748522435312",
  appId: "1:748522435312:web:77446d3aadb7db718a8835",
  measurementId: "G-F408K6NW5C"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth();

export { app, authentication };
